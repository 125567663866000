import * as React from 'react'
// import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import Seo from '../components/seo'
import DestinationInnPart from '../components/home/destinationInn'
import StyledPOne from '../components/typography/ParaOne'
import Button from '../components/buttons/buttons'

const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 64px;
`
const Contact = () => (
  <Layout>
    <Seo
      title="Contact Us"
      description="Destination Inn, premium lodging in Radium Hot Springs"
      canonical="https://destinationinn.ca/contact/"
    />

    <StaticImage
      layout="fullWidth"
      loading="eager"
      quality={100}
      formats={['AUTO', 'WEBP', 'AVIF']}
      src="../images/map.jpg"
      alt="destination inn in radium hotsprings map picture"
      style={{ minHeight: `300px` }}
    />

    <Wrapper>
      <DestinationInnPart title="CONTACT US" lastLine="" />
      <StyledPOne text="TO BOOK YOUR RESERVATION" />
      <p>
        4881 St. Mary's Street
        <br />
        PO Box 10
        <br />
        Radium Hot Springs, BC
        <br />
      </p>
      <p>
        Phone: 250-347-9111
        <br />
        Email: destinationinn365@gmail.com
      </p>
      <a href="tel:+12503479111" title="call 1-250-357-9111">
        <Button cta="CALL NOW" />
      </a>
    </Wrapper>
  </Layout>
)

export default Contact
