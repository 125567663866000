import * as React from 'react'
import Pin from './pindrop_icon.svg'
import styled from '@emotion/styled'

const OuterWrapper = styled.div`
  max-width: var(--site-width);
  margin: 64px auto;

  p {
    color: var(--dark-blue);
    font-sizes: 16px;
  }
  .centerMe {
    text-align: center;
  }
  .mTop {
    margin-top: 0;
  }
  .mBot {
    margin-bottom: 0;
  }

  .bigSize {
    color: var(--dark-blue);
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: var(--red);
  }
  .pinImg {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .pinBlock {
  }
  @media (min-width: 768px) {
    .bigSize {
      font-size: 4rem;
    }
  }
`

const DestinationInnPart = ({ title, lastLine }) => (
  <>
    <OuterWrapper>
      <div className="pinBlock">
        <img className="pinImg" loading="lazy" height="100px" src={Pin} alt="destinination inn pin" />
        <p className="centerMe mTop">Destination Inn</p>
        <p className="centerMe bigSize mBot">{title}</p>
        <p className="centerMe mTop">{lastLine}</p>
      </div>
    </OuterWrapper>
  </>
)

export default DestinationInnPart
